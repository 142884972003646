<template>
  <div style="width: 100%; text-align:center;">
    <img :src="this.getEndpoint+'/images/logo.png'" width="200px;" style="margin-top: 20px; margin-bottom: 20px; text-align: center" alt="">
    <h1 style=" text-align: center">Reset Your Password.</h1>
    <p>Enter your new password.</p>
    <v-container style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <v-form
        style="width: 100%;"
        ref="form"
        v-model="enabled"
        lazy-validation
      >
        <v-text-field
          label="Password"
          required
          autofocus
          outlined
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          :disabled="requestSentSuccess"
          :rules="passwordRules"
          v-model="password"
        ></v-text-field>
        <v-text-field
          label="Confirm Password"
          required
          outlined
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          :disabled="requestSentSuccess"
          :rules="confirmPasswordRules"
          v-model="confirmPassword"
        ></v-text-field>
        <v-btn :disabled="!enabled || requestSentSuccess" block color="success" @click="requestPasswordReset" :loading="loader">Reset Password</v-btn>
      </v-form>
      <span v-if="requestSent" class="mt-5">
        <p>✅ Request sent.</p>
        <span v-if="requestSentSuccess">
          <h2>✅ Request was successful. You may close this window and attempt to login.</h2>
        </span>
        <span v-else>
          <p>❌ Request was unsuccessful.</p>
        </span>
      </span>
    </v-container>
    <v-snackbar
      v-model="snackBar"
      :timeout="snackTime"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import axios from 'axios';
import {mapGetters} from "vuex";
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      show1: false,
      show2: false,
      loader: false,
      snackBar: false,
      requestSent: false,
      requestSentSuccess: false,
      password: "",
      confirmPassword: "",
      requestSentMsg: "",
      snackText: '',
      snackTime: 3000,
      email: '',
      enabled: true,
      emailRules: [
        v => !!v || 'Email is required',
        v => (/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || "Email not recognized."
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      confirmPasswordRules: [
        v => !!v || 'Password is required',
        v => v===this.password || 'Passwords do not match.',
      ],

    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getBranchData', 'getBranch', 'envConstants', 'getGlobalValue', 'getPerms','menuItems'])
  },
  methods: {
    async requestPasswordReset(){
      try {
        this.loader = true;
        console.log(this.$route)
        let res = await axios.post(`${this.getEndpoint}/auth/reset`, {password: this.password, confirmPassword: this.confirmPassword, token: this.$route.query.tkn})
        console.log(res.data)
        if(res.data.error) throw res.data.error
        if(!res.data.success){
          this.requestSentSuccess = false
          this.requestSentMsg =res.data.error
        }
        else this.requestSentSuccess = true
      } catch (e) {
        this.requestSentSuccess = false
        console.log(e.msg)
        this.snackText = e
      } finally {
        this.loader = false;
        this.requestSent = true
      }
    },
  }
}
</script>
