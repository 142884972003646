<template>
  <div class="Login" style="width: 100%; text-align:center;">
    <img :src="this.getEndpoint+'/images/logo.png'" width="200px;" style="margin-top: 20px; margin-bottom: 20px; text-align: center" alt="">
    <h1 style=" text-align: center">Reset Your Password.</h1>
    <router-link to="/login">Back to login</router-link>
    <p>Enter your account username or email. If we can find a match, we will send a password reset link to your email.</p>
    <v-container style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <v-form
        style="width: 100%;"
        ref="form"
        v-model="enabled"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-text-field
              label="Email"
              type="email"
              required
              autofocus
              outlined
              :disabled="requestSentSuccess"
              :rules="emailRules"
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn :disabled="!enabled || requestSentSuccess" block color="success" @click="requestPasswordReset" :loading="loader">Request Reset</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <span v-if="requestSent" class="mt-5">
        <p>✅ Request sent.</p>
        <span v-if="requestSentSuccess">
          <h2>✅ Request was successful. Check your {{ email }} inbox.</h2>
          <ul>
            <li>The email link expires in a short time. Please reset your password ASAP.</li>
            <li>If the email link has expired, you will need to restart this process.</li>
            <li>You may need to check your spam folder for the email.</li>
            <li>The reset link can only be used once.</li>
          </ul>
          <p> You may close this window</p>
        </span>
        <span v-else>
          <p>❌ Request was unsuccessful. {{ requestSentMsg }}</p>
        </span>
      </span>
    </v-container>
    <v-snackbar
      v-model="snackBar"
      :timeout="snackTime"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import axios from 'axios';
import {mapGetters} from "vuex";
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      loader: false,
      snackBar: false,
      requestSent: false,
      requestSentSuccess: false,
      requestSentMsg: "",
      snackText: '',
      snackTime: 3000,
      email: '',
      enabled: true,
      emailRules: [
        v => !!v || 'Email is required',
        v => (/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || "Email not recognized."
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],

    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getBranchData', 'getBranch', 'envConstants', 'getGlobalValue', 'getPerms','menuItems'])
  },
  methods: {
    async requestPasswordReset(){
      try {
        if(this.getGlobalValue('PASSWORD_RESET_EMAIL_ALLOWED')==="false") throw "Email Password reset not enabled."
        console.log("HERERERERE")
        this.loader = true;
        let res = await axios.post(`${this.getEndpoint}/auth/secureResetPassword`, {email: this.email})
        console.log(res.data)
        if(res.data.error) throw res.data.error
        this.requestSentSuccess = true
      } catch (e) {
        this.requestSentSuccess = false
        this.requestSentMsg = e
      } finally {
        this.loader = false;
        this.requestSent = true
      }
    },
  }
}
</script>
