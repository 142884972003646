<template>
  <div class="Login" style="margin-bottom: 50px; width: 100%; text-align:center;">
    <img :src="this.getEndpoint+'/images/logo.png'" width="200px;" style="margin-top: 50px; margin-bottom: 50px; text-align: center" alt="">
    <h1 style=" text-align: center">{{this.envConstants.ADMIN_LOGIN_PAGE_TITLE}}</h1>
    <h3 style=" text-align: center">Login</h3>
    <v-container v-if="this.loader">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-container>
    <v-container v-else style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols=12>
            <v-text-field
              label="Username"
              type="text"
              :rules="usernameRules"
              required
              autofocus
              outlined
              v-model="username"
            ></v-text-field>
          </v-col>
          <v-col cols=12>
            <v-text-field
              label="Password"
              type="password"
              :rules="passwordRules"
              outlined
              required
              v-model="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex flex-column" cols=12>
            <v-btn :disabled="!valid" color="success" @click="login">Login</v-btn>
            <router-link class="mt-4" to="/requestPasswordReset">Reset Password</router-link>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar
      v-model="snackBar"
      :timeout="snackTime"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import axios from 'axios';
// axios.defaults.withCredentials = true;
import {mapGetters, mapMutations} from "vuex";
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      loader: false,
      snackBar: false,
      snackText: '',
      snackTime: 3000,
      username: '',
      password: '',
      valid: false,
      usernameRules: [
        v => !!v || 'Username is required',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],

    }
  },
  mounted(){
    this.removeIds()
    window.addEventListener('keypress',this.keyPressed)
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getBranchData', 'getBranch', 'envConstants', 'getGlobalValue', 'getPerms','menuItems', 'getCurrentRegister'])
  },
  methods: {
    ...mapMutations([
      'setUser',
      'setUserCache',
      'setToken',
      'removeIds',
      'setPerms',
      'setBranch',
      'setBranches',
      'setManaged',
      'activateScanSense',
      'setPaymentTypes',
      'setPaymentMethods',
      'setGLOBALS',
      'setQuickList',
      'setNotifications',
      'updateCurrentRegister'
    ]),
    async login(){
      try {
        if(this.valid){
          this.loader = true;
          let res = await axios.post(`${this.getEndpoint}/auth/login`, {username: this.username, password: this.password})
          if(res.data.success){
            this.setGLOBALS((await axios.get(`${this.getEndpoint}/api/globals`))?.data.data)

            if(this.getGlobalValue('registerModuleEnabled')==='true'){
              let reg = await axios.get(`${this.getEndpoint}/api/registers/verifyOpen/${this.getCurrentRegister}`)
              if(reg.data.error) console.log("APP.JS❌ Register Data Not Fetched.")
              this.updateCurrentRegister(res.data.registerUUID)
            }

            // console.log(res.data)
            window.removeEventListener('keypress', this.keyPressed)
            let notifs = await axios.get(`${this.getEndpoint}/api/notifications/user/${res.data.user.id}`)
            if(notifs.data.error) console.log("APP.JS❌ Notification Data Not Fetched.")
            else this.setNotifications(notifs.data.data)
            this.setPaymentTypes((await axios.get(`${this.getEndpoint}/api/paymenttypes/lite`))?.data.data)
            this.setPaymentMethods((await axios.get(`${this.getEndpoint}/api/paymentmethods/lite`))?.data.data)
            this.setQuickList((await axios.get(`${this.getEndpoint}/api/products/quicklist`))?.data.data)
            let branches = await axios.get(`${this.getEndpoint}/api/locations/branches`)
            let userNames = await axios.get(`${this.getEndpoint}/api/users/userNames`)
            if(branches.data.error) console.log("APP.JS❌ Location Data Not Fetched.")
            this.setBranches(branches.data.data);
            this.setUser(res.data.user)
            this.setPerms(res.data.user.perms)

            this.setBranch(res.data.user.metadata.branchData.main)
            this.setManaged(!!this.getBranchData(this.getBranch)?.metadata.isManaged)

            this.activateScanSense()
            if(userNames.data.error) console.log("APP.JS❌ User Cache Data Not Fetched.")
            else this.setUserCache(userNames.data.data)
            await this.$router.push({path: "/"})
          }else{
            this.snackText = res.data.error.msg
            this.snackBar = true;
          }
        }
      } catch (e) {
        console.log(e.msg)
        this.snackText = e
      } finally {
        this.loader = false;
      }
    },
    keyPressed(e){
      console.log("Hmph")
      if(e.key === 'Enter'){
        if(this.valid) this.login()
      } 
    }
  }
}
</script>
